// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallery-container {
    position: relative;
    width: 100%;
    height: auto;
  }
  
  .gallery-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
  }
  
  .gallery-button:hover {
    color: #CBC5B3;
    ;
  }
  
  .gallery-button:first-child {
    left: 10px;
  }
  
  .gallery-button:last-child {
    right: 10px;
  }
  
  .gallery-image {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto;
  }`, "",{"version":3,"sources":["webpack://./src/components/GalleryComponent.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,QAAQ;IACR,2BAA2B;IAC3B,6BAA6B;IAC7B,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,eAAe;EACjB;;EAEA;IACE,cAAc;;EAEhB;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,cAAc;IACd,cAAc;EAChB","sourcesContent":[".gallery-container {\n    position: relative;\n    width: 100%;\n    height: auto;\n  }\n  \n  .gallery-button {\n    position: absolute;\n    top: 50%;\n    transform: translateY(-50%);\n    background-color: transparent;\n    border: none;\n    color: white;\n    font-size: 20px;\n    cursor: pointer;\n  }\n  \n  .gallery-button:hover {\n    color: #CBC5B3;\n    ;\n  }\n  \n  .gallery-button:first-child {\n    left: 10px;\n  }\n  \n  .gallery-button:last-child {\n    right: 10px;\n  }\n  \n  .gallery-image {\n    width: 100%;\n    height: auto;\n    display: block;\n    margin: 0 auto;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
