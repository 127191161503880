import React, { useState } from "react";
import "./GalleryComponent.css"; // Import CSS for styling (customize as needed)

const GalleryComponent = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div id="gallerysection" className="gallery-container">
      <button className="gallery-button" onClick={prevImage}>
        <img src="assets/prev.svg" className="gallery-control-btn" />
      </button>
      <img
        className="gallery-image"
        src={images[currentImageIndex]}
        alt={`Image ${currentImageIndex}`}
      />
      <button className="gallery-button" onClick={nextImage}>
        <img src="assets/next.svg" className="gallery-control-btn" />
      </button>
    </div>
  );
};

export default GalleryComponent;
