import React from "react";
import EstateComponent from "./EstateComponent";
import AmenitiesComponents from "./AmenitiesComponents";
import Footer from "./Footer";

import SecoundHeaderComponents from "./SecoundHeaderComponents";

const Experience = () => {
  return (
    <div>
      <SecoundHeaderComponents />

      <EstateComponent />
      <AmenitiesComponents />
      <Footer />
    </div>
  );
};

export default Experience;
