import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import HeaderComponents from "./HeaderComponents";
import AboutComponent from "./AboutComponent";

const VideoContainer = styled("div")({
  position: "relative",
  width: "100vw",
  height: "100vh", // Set height to fill viewport height
  overflow: "hidden", // Hide overflow to prevent scrolling
});

const Video = styled("video")({
  width: "100%",
  height: "100%", // Set height to fill container height
  objectFit: "cover", // Cover the container with the video
});

const LandingVideoComponent = () => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 900); // Adjust breakpoint as needed
    };
    handleResize(); // Set initial view
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleClick = (event) => {
    window.location.href = "/contact";
  };

  const TextOverlayLogo = styled("div")({
    position: "absolute",
    top: "10%", // Center vertically
    left: "20%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    color: "white",
    fontWeight: "bold",
  });

  const LogoImage = styled("img")({
    width: "150px", // Adjust size as needed
    height: "100px",
  });

  const TextOverlayRequestVisitButton = styled(Button)({
    fontFamily: "bodyFont",
    position: "absolute",
    top: theme.breakpoints.values.sm ? "10%" : "10%", // Adjust top percentage for mobile view
    // left: "75%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    color: "black", // Change text color to black
    fontSize: theme.breakpoints.values.sm ? "10px" : "10px", // Adjust font size for mobile view
    backgroundColor: "white", // Change background color to white
    borderRadius: "0", // Remove border radius for sharp edges
    "&:hover": {
      // Change color on hover
      backgroundColor: "#CBC5B3",
    },
  });

  const TextOverlayTitle = styled("div")({
    fontFamily: "sectionTitleFont",
    position: "absolute",
    top: theme.breakpoints.values.sm ? "30%" : "25%", // Adjust top percentage for mobile view
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    color: "white",
    fontSize: theme.breakpoints.values.sm ? "30px" : "20px", // Adjust font size for mobile view
    fontWeight: "bold",
  });

  const TextOverlaySubTitle = styled("div")({
    fontFamily: "sectionTitleFont",
    position: "absolute",
    top: theme.breakpoints.values.sm ? "50%" : "60%", // Adjust top percentage for mobile view
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    color: "white",
    fontSize: theme.breakpoints.values.sm ? "18px" : "18px", // Adjust font size for mobile view
  });

  const TextOverlayDownloadButton = styled(Button)({
    fontFamily: "bodyFont",
    position: "absolute",
    top: theme.breakpoints.values.sm ? "70%" : "80%", // Adjust top percentage for mobile view
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    color: "black", // Change text color to black
    fontSize: theme.breakpoints.values.sm ? "10px" : "10px", // Adjust font size for mobile view
    backgroundColor: "white", // Change background color to white
    borderRadius: "0", // Remove border radius for sharp edges
    "&:hover": {
      // Change color on hover
      backgroundColor: "#CBC5B3",
    },
  });

  return (
    <VideoContainer>
      <Video autoPlay loop muted playsInline>
        <source src="/assets/banner_video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </Video>
      {isMobileView ? (
        <>
          {/* <TextOverlayLogo>
            <LogoImage src="/assets/logo.png" alt="Logo" />
          </TextOverlayLogo> */}
          {/* <TextOverlayRequestVisitButton
            onClick={handleClick}
            variant="contained"
            className="request-btn"
          >
            Request Visit
          </TextOverlayRequestVisitButton> */}
          <TextOverlayTitle>
            Life Begins at Arabian Hills Estate
          </TextOverlayTitle>
          <TextOverlaySubTitle>
            MASTER PLANNED RESIDENTIAL COMMUNITY
          </TextOverlaySubTitle>
          <TextOverlayDownloadButton onClick={handleClick} variant="contained">
            Contact us
          </TextOverlayDownloadButton>
        </>
      ) : (
        <>
          <div className="video-container">
            <TextOverlayTitle className="title-video-container">
              Life Begins <br /> at Arabian Hills Estate
            </TextOverlayTitle>
            <TextOverlaySubTitle className="SubTitle-video-container">
              MASTER PLANNED RESIDENTIAL COMMUNITY
            </TextOverlaySubTitle>
            <TextOverlayDownloadButton
              onClick={handleClick}
              variant="contained"
              className="broucher-btn"
            >
              Contact us
            </TextOverlayDownloadButton>
          </div>
        </>
      )}

      {/* <HeaderComponents open={isPopupOpen} onClose={handleClosePopup} /> */}
    </VideoContainer>
  );
};

export default LandingVideoComponent;
