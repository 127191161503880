import React from "react";
import DevloperComponets from "./DevloperComponets";
import PartnersComponents from "./PartnersComponents";
import Footer from "./Footer";
import SecoundHeaderComponents from "./SecoundHeaderComponents";

const Partners = () => {
  return (
    <div>
      <SecoundHeaderComponents />
      <DevloperComponets />
      <PartnersComponents />
      <Footer />
    </div>
  );
};

export default Partners;
