// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h6.clustor-title {
  color: var(--White, #fff);
  font-family: "Playfair Display" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  opacity: 0.6;
}

ul.clustor-list {
  padding: 0px !important;
}
.rectangle-8 {
  transition: 0.3s;
}
/* .rectangle-8:hover {
  transform: scale(1.1);
} */
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .clustor-boxes-section-main {
    margin-top: 3rem !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ClustersComponent.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,0CAA0C;EAC1C,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,uBAAuB;AACzB;AACA;EACE,gBAAgB;AAClB;AACA;;GAEG;AACH;EACE;IACE,2BAA2B;EAC7B;AACF","sourcesContent":["h6.clustor-title {\n  color: var(--White, #fff);\n  font-family: \"Playfair Display\" !important;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  text-transform: uppercase;\n  opacity: 0.6;\n}\n\nul.clustor-list {\n  padding: 0px !important;\n}\n.rectangle-8 {\n  transition: 0.3s;\n}\n/* .rectangle-8:hover {\n  transform: scale(1.1);\n} */\n@media only screen and (min-width: 768px) and (max-width: 1199px) {\n  .clustor-boxes-section-main {\n    margin-top: 3rem !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
