import React from "react";
import AboutComponent from "./AboutComponent";
import LocationComponent from "./LocationComponent";
import ClustersComponent from "./ClustersComponent";
import LandingVideoComponent from "./LandingVideoComponent";
import Footer from "./Footer";
import SecoundHeaderComponents from "./SecoundHeaderComponents";
import GalleryComponent from "./GalleryComponent";
import gallery1 from "../gallery1.png"; // Import local images
import gallery2 from "../gallery2.png";
import gallery3 from "../gallery3.png";

const HomeComponents = () => {
  const images = [gallery1, gallery2, gallery3];
  return (
    <div>
      <SecoundHeaderComponents />
      <LandingVideoComponent />
      <AboutComponent />
      <GalleryComponent images={images} />
      <LocationComponent />
      <ClustersComponent />
      <Footer />
    </div>
  );
};

export default HomeComponents;
