import React from "react";
import { Typography, Grid, Link } from "@mui/material";
import "./Footer.css";

function Footer() {
  return (
    <footer>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ py: 4 }}
      >
        <div className="main-container-footer">
          <div className="footer-menu-row ">
            <ul className="footer-menu">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/experience">EXPERIENCES</a>
              </li>
              <li>
                <a href="/partners">PARTNERS</a>
              </li>
              <li>
                <a href="/contact">Contact</a>
              </li>
            </ul>
          </div>
          <div className="logo-row">
            <a href="/">
              <img src="assets/logo-black.png" className="footer-logo" />
            </a>
          </div>

          <div className="social-links-row">
            <ul>
              <li className="social-links-item">
                <a href="mailto:info@deca-properties.com;">
                  info@deca-properties.com
                  <img
                    src="assets/mail-icon.svg"
                    className="socail-icons-footer"
                  />
                </a>
              </li>

              <li className="social-links-item">
                <a href="tel:+971568686786">
                  +971-5686 86 786
                  <img
                    src="assets/phone-icon.svg"
                    className="socail-icons-footer"
                  />
                </a>
              </li>
            </ul>

            <ul className="social-links">
              <li className="social-links-item utc-text">
                <a href="#">
                  UTC B-14, Main Sheikh Zayed Road, Al Wasl, Dubai, UAE
                  <img src="assets/map.svg" className="socail-icons-footer" />
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="copyright-row">
          <div className="copyright-asha">
            <p>Copyright © 2024, DECA. All rights reserved.</p>
          </div>

          <div className="terms-row">
            <p>Terms and Conditions</p>
            <p style={{}}>Privacy Policy</p>
          </div>
        </div>
      </Grid>
    </footer>
  );
}

export default Footer;
