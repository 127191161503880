// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.partners-container {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding-bottom: 47px;
}
.partners-des {
  opacity: 1 !important;
}

h2.titlepartners {
  color: #000;
  font-family: "Playfair Display", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

img.partners-logo {
  width: 150px;
}

.parners-logo-card {
  flex-basis: 13.333333% !important;
  display: flex;
  justify-content: flex-start;
  gap: 0;
}

p.partners-para {
  padding-left: 47px;
}
`, "",{"version":3,"sources":["webpack://./src/components/PartnersComponents.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;EACrB,mBAAmB;EACnB,2BAA2B;EAC3B,eAAe;EACf,oBAAoB;AACtB;AACA;EACE,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,2CAA2C;EAC3C,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iCAAiC;EACjC,aAAa;EACb,2BAA2B;EAC3B,MAAM;AACR;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".partners-container {\n  display: flex;\n  align-content: center;\n  align-items: center;\n  justify-content: flex-start;\n  flex-wrap: wrap;\n  padding-bottom: 47px;\n}\n.partners-des {\n  opacity: 1 !important;\n}\n\nh2.titlepartners {\n  color: #000;\n  font-family: \"Playfair Display\", sans-serif;\n  font-size: 32px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n}\n\nimg.partners-logo {\n  width: 150px;\n}\n\n.parners-logo-card {\n  flex-basis: 13.333333% !important;\n  display: flex;\n  justify-content: flex-start;\n  gap: 0;\n}\n\np.partners-para {\n  padding-left: 47px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
