// import React from "react";

// import "./AboutComponent.css";
// import { Grid, Typography } from "@mui/material";
// import { useTheme } from "@mui/material/styles";

// const AboutComponent = () => {
//   const theme = useTheme();

//   const sectionTitle = {
//     fontFamily: "sectionTitleFont",
//     color: "#CBC5B3",
//     paddingTop: "10px",
//     paddingBottom: "10px",
//   };

//   const boxStyle = {
//     padding: theme.spacing(2), // Responsive padding
//     textAlign: "center",
//     borderRadius: theme.spacing(1), // Responsive border-radius
//     border: "1px solid #CBC5B3",
//     height: "150px",
//   };

//   return (
//     <Grid
//       className="about-container"
//       container
//       spacing={2}
//       sx={{ pl: "50px", pr: "50px", pt: "50px", pb: "100px" }}
//     >
//       {/* First column with two rows */}
//       <Grid item xs={6}>
//         <Grid container direction="column" spacing={2}>
//           <Grid item xs={12} sm={3} xl={5}>
//             <Typography
//               className="about"
//               id="aboutsection"
//               style={sectionTitle}
//               sx={{ fontFamily: "sectionTitleFont" }}
//             >
//               ABOUT
//             </Typography>
//             <Typography
//               sx={{ fontFamily: "titleFont" }}
//               className="location-sub"
//             >
//               LIFE BEGINS HERE
//             </Typography>
//             <Typography
//               sx={{ fontFamily: "bodyFont", pb: "10px" }}
//               class="discription-pera"
//             >
//               Welcome to a place where you can live the life you've always
//               dreamt of. Poised to be the most coveted community in the UAE,
//               Arabian Hills Estate is where you’ll build your perfect home and
//               craft your ideal lifestyle in a vibrant community.
//               <br />
//               <br />
//               Exceptional amenities await here, from outstanding equestrian
//               facilities to swimmable lagoons, and shopping malls to world-class
//               resorts, and a wide array of extraordinary experiences right on
//               your doorstep.
//             </Typography>
//           </Grid>
//           <Grid item>
//             <Typography
//               sx={{ fontFamily: "titleFont", pb: "20px" }}
//               className="location-sub"
//             >
//               PROJECT DETAILS
//             </Typography>
//             <Grid className="about-grid" container spacing={2}>
//               <Grid item xs={12} sm={3}>
//                 <div style={boxStyle} className="box-1">
//                   <Typography
//                     sx={{ fontFamily: "titleFont" }}
//                     className="about"
//                   >
//                     PROJECT NAME
//                   </Typography>
//                   <Typography
//                     sx={{ fontFamily: "bodyFont" }}
//                     className="about-sub-box"
//                   >
//                     Arabian Hills Estate
//                   </Typography>
//                 </div>
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <div style={boxStyle} className="box-2">
//                   <Typography
//                     sx={{ fontFamily: "titleFont" }}
//                     className="about"
//                   >
//                     PROJECT TYPE
//                   </Typography>
//                   <Typography
//                     sx={{ fontFamily: "bodyFont" }}
//                     className="about-sub-box"
//                   >
//                     Master Planned Residential Community
//                   </Typography>
//                 </div>
//               </Grid>
//               <Grid item xs={12} sm={3}>
//                 <div style={boxStyle} className="box-1">
//                   <Typography
//                     sx={{ fontFamily: "titleFont" }}
//                     className="about"
//                   >
//                     COMPLETION
//                   </Typography>
//                   <Typography
//                     sx={{ fontFamily: "bodyFont" }}
//                     className="about-sub-box"
//                   >
//                     2027
//                   </Typography>
//                 </div>
//               </Grid>
//               <Grid item xs={12} sm={3}>
//                 <div style={boxStyle} className="box-1">
//                   <Typography
//                     sx={{ fontFamily: "titleFont" }}
//                     className="about"
//                   >
//                     VILLA PLOTS
//                   </Typography>
//                   <Typography
//                     sx={{ fontFamily: "bodyFont" }}
//                     className="about-sub-box"
//                   >
//                     12,000 sq.ft to 20,000 sq.ft
//                   </Typography>
//                 </div>
//               </Grid>
//               <Grid item xs={12} sm={3}>
//                 <div style={boxStyle} className="box-1">
//                   <Typography
//                     sx={{ fontFamily: "titleFont" }}
//                     className="about"
//                   >
//                     MANSION PLOTS
//                   </Typography>
//                   <Typography
//                     sx={{ fontFamily: "bodyFont" }}
//                     className="about-sub-box"
//                   >
//                     20,000 sq.ft to 50,000 sq.ft
//                   </Typography>
//                 </div>
//               </Grid>
//               <Grid item xs={12} sm={3} className="box-1">
//                 <div style={boxStyle} className="box-1">
//                   <Typography
//                     sx={{ fontFamily: "titleFont" }}
//                     className="about"
//                   >
//                     MEGA MANSION PLOTS
//                   </Typography>
//                   <Typography
//                     sx={{ fontFamily: "bodyFont" }}
//                     className="about-sub-box"
//                   >
//                     50,000 sq.ft to 76,000 sq.ft
//                   </Typography>
//                 </div>
//               </Grid>
//               <Grid item xs={12} sm={3}>
//                 <div style={boxStyle} className="box-1">
//                   <Typography
//                     sx={{ fontFamily: "titleFont" }}
//                     className="about"
//                   >
//                     ESTATE PLOTS
//                   </Typography>
//                   <Typography
//                     sx={{ fontFamily: "bodyFont" }}
//                     className="about-sub-box"
//                   >
//                     76,000 sq.ft to 132,000 sq.ft
//                   </Typography>
//                 </div>
//               </Grid>
//             </Grid>
//           </Grid>
//         </Grid>
//       </Grid>

//       {/* Second column with one row */}
//       <Grid
//         className="img-col"
//         item
//         xs={12}
//         xl={4}
//         sm={6}
//         md={6}
//         sx={{ textAlign: "center", pt: "20px" }}
//       >
//         <img
//           className="rectangle-10"
//           src="/assets/about.png"
//           style={{ maxWidth: "80%!important", height: "100%" }}
//         />
//       </Grid>
//     </Grid>

//     // demo code grid
//   );
// };

// export default AboutComponent;
import React, { useEffect } from "react";

import "./AboutComponent.css";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AOS from "aos";
import "aos/dist/aos.css";
const AboutComponent = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  const theme = useTheme();

  const sectionTitle = {
    fontFamily: "sectionTitleFont",
    color: "#CBC5B3",
    paddingTop: "10px",
    paddingBottom: "10px",
  };

  const boxStyle = {
    padding: theme.spacing(2), // Responsive padding
    textAlign: "center",
    borderRadius: theme.spacing(1), // Responsive border-radius
    border: "1px solid #313B2F",
    height: "150px",
  };

  return (
    <Grid
      className="about-container"
      container
      spacing={2}
      sx={{
        pl: "50px",
        pr: "50px",
        pt: "200px!important",
        // pb: "100px",
        backgroundImage: "url('/assets/Ameneties.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Grid with desired layout */}
      <Grid className="grid-mobile-aboutus" container spacing={2}>
        <Grid item xs={8} className="about-des-box">
          {/* First column with two rows */}
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12} sm={3} xl={5}>
              <Typography
                className="about"
                id="aboutsection"
                style={sectionTitle}
                sx={{ fontFamily: "sectionTitleFont" }}
              >
                ABOUT
              </Typography>
              <Typography
                sx={{ fontFamily: "titleFont" }}
                className="location-sub"
              >
                LIFE BEGINS HERE
              </Typography>
              <Typography
                sx={{ fontFamily: "bodyFont", pb: "10px" }}
                class="discription-pera"
              >
                Welcome to a place where you can live the life you've always
                dreamt of. Poised to be the most coveted community in the UAE,
                Arabian Hills Estate is where you’ll build your perfect home and
                craft your ideal lifestyle in a vibrant community.
                <br />
                <br />
                Exceptional amenities await here, from outstanding equestrian
                facilities to swimmable lagoons, and shopping malls to
                world-class resorts, and a wide array of extraordinary
                experiences right on your doorstep.
              </Typography>
            </Grid>

            <Grid
              data-aos="fade-right"
              data-aos-delay="200"
              sx={{ pt: "10px", pl: "0px" }}
              item
              className="about-grid-mobile-boxes"
            >
              <Typography
                sx={{ fontFamily: "titleFont", pb: "20px", textAlign: "left" }}
                className="location-sub"
              >
                PROJECT DETAILS
              </Typography>
              <div className="boxs-border-cards">
                <div className="box-body">
                  <Typography
                    sx={{ fontFamily: "titleFont" }}
                    className="about"
                  >
                    PROJECT NAME
                  </Typography>
                  <Typography
                    sx={{ fontFamily: "bodyFont" }}
                    className="about-sub-box"
                  >
                    Arabian Hills Estate
                  </Typography>
                </div>

                <div className="box-body">
                  <Typography
                    sx={{ fontFamily: "titleFont" }}
                    className="about"
                  >
                    PROJECT TYPE
                  </Typography>
                  <Typography
                    sx={{ fontFamily: "bodyFont" }}
                    className="about-sub-box"
                  >
                    Master Planned Residential Community
                  </Typography>
                </div>

                <div className="box-body">
                  <Typography
                    sx={{ fontFamily: "titleFont" }}
                    className="about"
                  >
                    COMPLETION
                  </Typography>
                  <Typography
                    sx={{ fontFamily: "bodyFont" }}
                    className="about-sub-box"
                  >
                    2027
                  </Typography>
                </div>
              </div>

              <div className="boxs-border-cards box-secound-div">
                <div className="box-body">
                  <Typography
                    sx={{ fontFamily: "titleFont" }}
                    className="about"
                  >
                    Villa Plots
                  </Typography>
                  <Typography
                    sx={{ fontFamily: "bodyFont" }}
                    className="about-sub-box"
                  >
                    12,000 sq.ft to 20,000 sq.ft
                  </Typography>
                </div>

                <div className="box-body">
                  <Typography
                    sx={{ fontFamily: "titleFont" }}
                    className="about"
                  >
                    Mansion Plots
                  </Typography>
                  <Typography
                    sx={{ fontFamily: "bodyFont" }}
                    className="about-sub-box"
                  >
                    20,000 sq.ft to 50,000 sq.ft
                  </Typography>
                </div>

                <div className="box-body">
                  <Typography
                    sx={{ fontFamily: "titleFont" }}
                    className="about"
                  >
                    Mega Mansion Plots
                  </Typography>
                  <Typography
                    sx={{ fontFamily: "bodyFont" }}
                    className="about-sub-box"
                  >
                    50,000 sq.ft to 76,000 sq.ft
                  </Typography>
                </div>

                <div className="box-body">
                  <Typography
                    sx={{ fontFamily: "titleFont" }}
                    className="about"
                  >
                    Estate Plots
                  </Typography>
                  <Typography
                    sx={{ fontFamily: "bodyFont" }}
                    className="about-sub-box"
                  >
                    76,000 sq.ft to 132,000 sq.ft
                  </Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          data-aos="fade-left"
          data-aos-delay="200"
          className="bg-image-col"
          item
          xs={4}
          sx={{
            textAlign: "center",
            pl: "20px!important",
            pr: "30px!important",
            pt: "20px",

            backgroundImage: "url(/assets/about.png)",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {/* Second column with one row */}
          <Grid
            className="img-col"
            item
            xl={4}
            sm={6}
            md={6}
            sx={{
              textAlign: "center",
              pl: "20px",
              pt: "20px",
            }}
          ></Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AboutComponent;
