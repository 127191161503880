import React from "react";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import "./EstateComponent.css";
const EstateComponent = () => {
  const theme = useTheme();
  const sectionTitle = {
    fontFamily: "sectionTitleFont",
    color: "black",
    paddingTop: "10px",
    paddingBottom: "10px",
  };

  return (
    <Grid
      container
      spacing={2}
      className="Feature-container-main"
      sx={{
        pl: "50px",
        pt: "250px",
        backgroundImage: "url(/assets/Ameneties-estaste.png)",
        backgroundSize: "cover",
        backgroundPosition: "center",

        pb: "100px",
      }}
    >
      <Grid
        className="estate-section-main"
        data-aos="fade-up"
        data-aos-duration="1000"
        item
        xs={10}
        sm={8}
      >
        <Typography
          id="estatesection"
          style={sectionTitle}
          className="location-head"
          sx={{ fontFamily: "sectionTitleFont" }}
        >
          THE ESTATE
        </Typography>
        <Typography sx={{ fontFamily: "titleFont" }} className="location-sub">
          FEATURES
        </Typography>
        <Typography
          className="discription-pera"
          sx={{ fontFamily: "bodyFont", pt: "10px", pb: "10px" }}
        >
          Discover a place with experiences beyond imagination, and everyday
          adventures that make you feel alive.
        </Typography>
        {/* Rectangle with specified dimensions and background color */}
        <div
          data-aos="fade-up"
          // data-aos-duration="1000"
          data-aos-delay="100"
          className="features-container"
          style={{
            width: "90vw", // Fill the viewport width
            height: "112px",
            backgroundColor: "#CBC5B3",
            display: "flex", // Use Flexbox
            justifyContent: "space-between", // Evenly distribute items along the row
            alignItems: "center", // Center items vertically
            padding: "0 10px", // Add padding to space items
            marginTop: "30px",
            marginBottom: "30px",
            borderRadius: "8px",
          }}
        >
          <div
            className="card-features"
            style={{ flex: "1", textAlign: "center" }}
          >
            <Typography
              variant="body2"
              className="about estate"
              style={{ pb: "0px!important" }}
            >
              WATER
            </Typography>
            <Typography
              variant="body2"
              className="about estate"
              style={{ pb: "0px!important" }}
            >
              AMENITIES
            </Typography>
          </div>
          <div
            className="card-features"
            style={{ flex: "1", textAlign: "center" }}
          >
            <img src="/assets/estate-icons/swimmablelagoons.svg" alt="Logo 1" />
            <Typography variant="body2"> Swimmable Lagoons </Typography>
          </div>
          <div
            className="card-features"
            style={{ flex: "1", textAlign: "center" }}
          >
            <img src="/assets/estate-icons/beach.svg" alt="Logo 2" />
            <Typography variant="body2"> Beach </Typography>
          </div>
          <div
            className="card-features"
            style={{ flex: "1", textAlign: "center" }}
          >
            <img src="/assets/estate-icons/splashpad.svg" alt="Logo 1" />
            <Typography variant="body2"> Splash pad </Typography>
          </div>
          <div
            className="card-features"
            style={{ flex: "1", textAlign: "center" }}
          >
            <img src="/assets/estate-icons/adultspool.svg" alt="Logo 2" />
            <Typography variant="body2"> Adults pool </Typography>
          </div>
          <div
            className="card-features"
            style={{ flex: "1", textAlign: "center" }}
          >
            <img src="/assets/estate-icons/bubblepool.svg" alt="Logo 1" />
            <Typography variant="body2"> Bubble pool </Typography>
          </div>
          <div
            className="card-features"
            style={{ flex: "1", textAlign: "center" }}
          >
            <img src="/assets/estate-icons/loungepool.svg" alt="Logo 2" />
            <Typography variant="body2"> Lounge pool </Typography>
          </div>
          {/* Repeat for remaining items */}
        </div>
        <div
          data-aos="fade-up"
          data-aos-delay="200"
          className="features-container secound-bg-estate"
          style={{
            width: "90vw", // Fill the viewport width
            height: "112px",
            backgroundColor: "#CBC5B3",
            display: "flex", // Use Flexbox
            justifyContent: "space-between", // Evenly distribute items along the row
            alignItems: "center", // Center items vertically
            padding: "0 10px", // Add padding to space items
            marginTop: "30px",
            marginBottom: "30px",
            borderRadius: "8px",
          }}
        >
          <div
            className="card-features"
            style={{ flex: "1", textAlign: "center" }}
          >
            <Typography
              variant="body2"
              className="about estate"
              style={{ pb: "0px!important" }}
            >
              GREEN
            </Typography>
            <Typography
              variant="body2"
              className="about estate"
              style={{ pb: "0px!important" }}
            >
              SPACES
            </Typography>
          </div>
          <div
            className="card-features"
            style={{ flex: "1", textAlign: "center" }}
          >
            <img src="/assets/estate-icons/openparks.svg" alt="Logo 1" />
            <Typography variant="body2"> Open parks </Typography>
          </div>
          <div
            className="card-features"
            style={{ flex: "1", textAlign: "center" }}
          >
            <img src="/assets/estate-icons/playarea.svg" alt="Logo 2" />
            <Typography variant="body2"> Play areas </Typography>
          </div>
          <div
            className="card-features"
            style={{ flex: "1", textAlign: "center" }}
          >
            <img src="/assets/estate-icons/picnicarea.svg" alt="Logo 1" />
            <Typography variant="body2"> Picnic areas </Typography>
          </div>
          <div
            className="card-features"
            style={{ flex: "1", textAlign: "center" }}
          >
            <img src="/assets/estate-icons/naturetrails.svg" alt="Logo 2" />
            <Typography variant="body2"> Nature trails </Typography>
          </div>
          <div
            className="card-features"
            style={{ flex: "1", textAlign: "center" }}
          >
            <img src="/assets/estate-icons/zengarden.svg" alt="Logo 1" />
            <Typography variant="body2"> Zen garden </Typography>
          </div>
          <div
            className="card-features"
            style={{ flex: "1", textAlign: "center" }}
          >
            <img src="/assets/estate-icons/dogpark.svg" alt="Logo 2" />
            <Typography variant="body2"> Dog park </Typography>
          </div>
          {/* Repeat for remaining items */}
        </div>
        <div
          data-aos="fade-up"
          data-aos-delay="300"
          className="features-container third-bg-estate"
          style={{
            width: "90vw", // Fill the viewport width
            height: "112px",
            backgroundColor: "#CBC5B3",
            display: "flex", // Use Flexbox
            justifyContent: "space-between", // Evenly distribute items along the row
            alignItems: "center", // Center items vertically
            padding: "0 10px", // Add padding to space items
            marginTop: "30px",
            marginBottom: "30px",
            borderRadius: "8px",
          }}
        >
          <div
            className="card-features"
            style={{ flex: "1", textAlign: "center" }}
          >
            <Typography
              variant="body2"
              className="about estate"
              style={{ pb: "0px!important" }}
            >
              EDQUESTRIAN
            </Typography>
            <Typography
              variant="body2"
              className="about estate"
              style={{ pb: "0px!important" }}
            >
              FACILITIES
            </Typography>
          </div>
          <div
            className="card-features"
            style={{ flex: "1", textAlign: "center" }}
          >
            <img src="/assets/estate-icons/stables.svg" alt="Logo 1" />
            <Typography variant="body2"> Stables </Typography>
          </div>
          <div
            className="card-features"
            style={{ flex: "1", textAlign: "center" }}
          >
            <img src="/assets/estate-icons/tackroom.svg" alt="Logo 2" />
            <Typography variant="body2"> Tack room </Typography>
          </div>
          <div
            className="card-features"
            style={{ flex: "1", textAlign: "center" }}
          >
            <img src="/assets/estate-icons/paddocks.svg" alt="Logo 1" />
            <Typography variant="body2"> Paddocks </Typography>
          </div>
          <div
            className="card-features"
            style={{ flex: "1", textAlign: "center" }}
          >
            <img src="/assets/estate-icons/ridingarenas.svg" alt="Logo 2" />
            <Typography variant="body2"> Riding arenas </Typography>
          </div>
          <div
            className="card-features"
            style={{ flex: "1", textAlign: "center" }}
          >
            <img src="/assets/estate-icons/jumpingarena.svg" alt="Logo 1" />
            <Typography variant="body2"> Jumping arena </Typography>
          </div>
          <div
            className="card-features"
            style={{ flex: "1", textAlign: "center" }}
          >
            <img src="/assets/estate-icons/trainingschools.svg" alt="Logo 2" />
            <Typography variant="body2"> Training schools </Typography>
          </div>
          {/* Repeat for remaining items */}
        </div>
      </Grid>
    </Grid>
  );
};

export default EstateComponent;
