// // src/App.js
// import React from "react";
// import LandingVideoComponent from "./components/LandingVideoComponent";
// // import MenuComponent from "./components/MenuComponent";
// import AboutComponent from "./components/AboutComponent";
// import LocationComponent from "./components/LocationComponent";
// import GalleryComponent from "./components/GalleryComponent";
// import ClustersComponent from "./components/ClustersComponent";
// import EstateComponent from "./components/EstateComponent";
// import ContactComponent from "./components/ContactComponent";
// import gallery1 from "./gallery1.png"; // Import local images
// import gallery2 from "./gallery2.png";
// import gallery3 from "./gallery3.png";
// import DevloperComponets from "./components/DevloperComponets";
// import PartnersComponents from "./components/PartnersComponents";
// // import AmenitiesComponents from "./components/AmenitiesComponents";
// import Footer from "./components/Footer";

// function App() {
// const images = [gallery1, gallery2, gallery3];
//   return (
//     <>
//       <LandingVideoComponent />
//       {/* <MenuComponent /> */}
//       <AboutComponent />
//       <LocationComponent />
//       <GalleryComponent images={images} /> <ClustersComponent />
//       <EstateComponent />
//       {/* <AmenitiesComponents /> */}
//       <DevloperComponets />
//       <PartnersComponents />
//       <ContactComponent />
//       <Footer />
//     </>
//   );
// }

// export default App;
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingVideoComponent from "./components/LandingVideoComponent";
import AboutComponent from "./components/AboutComponent";
import LocationComponent from "./components/LocationComponent";
import GalleryComponent from "./components/GalleryComponent";
import ClustersComponent from "./components/ClustersComponent";
import EstateComponent from "./components/EstateComponent";
import ContactComponent from "./components/ContactComponent";
import Footer from "./components/Footer";
import HomeComponents from "./components/HomeComponents";
import Partners from "./components/Partners";
import Experience from "./components/Experience";
import Contact from "./components/Contact";

import AOS from "aos";

function App() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomeComponents />} />
        <Route path="/About-us" element={<AboutComponent />} />
        <Route path="/location" element={<LocationComponent />} />
        <Route path="/Estate" element={<EstateComponent />} />
        <Route path="/gallery" element={<GalleryComponent />} />
        <Route path="/Contact-us" element={<ContactComponent />} />
        <Route path="/Clusters" element={<ClustersComponent />} />
        <Route path="/Footer" element={<Footer />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/experience" element={<Experience />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  );
}

export default App;
