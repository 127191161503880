import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import "./DevloperComponets.css";

const DevloperComponets = () => {
  const theme = useTheme();
  const sectionTitle = {
    fontFamily: "sectionTitleFont",
    color: "white",
    paddingTop: "10px",

    paddingBottom: "10px",
  };

  return (
    <Grid
      id="devloper"
      className="devloper-container"
      container
      spacing={2}
      sx={{
        pl: "50px",
        pr: "50px",
        pb: "50px",
        pt: "250px",
        pb: "100px",
        backgroundImage: "url(/assets/Developer-bg.png)",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Grid
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-offset="300"
        data-aos-delay="100"
        item
        xs={12}
        sm={8}
        sx={{ pb: "20px" }}
      >
        <Typography
          id="developersection"
          style={sectionTitle}
          sx={{ fontFamily: "sectionTitleFont", color: "white" }}
        >
          DEVELOPER
        </Typography>
        <Typography sx={{ fontFamily: "titleFont", color: "white" }}>
          <h2> Arabian Hills Estate </h2>
        </Typography>
        <Typography
          className="discription-pera"
          sx={{
            fontFamily: "bodyFont",
            color: "white!important",
            fontSize: "20px!important",
            opacity: "1!important",
            paddingTop: "20px",
          }}
        >
          A leading Real Estate Master Developer is revolutionizing property
          development in the region.With a focus on innovation and
          sustainability, their upcoming residential and commercial projects are
          designed to offer exceptional investment opportunities aligning with
          Abu Dhabi 's 2030 vision. These distinctive developments will set new
          benchmarks, delivering cutting - edge concepts that contribute to a
          prosperous future for the emirate.
        </Typography>
      </Grid>
      <Grid
        data-aos="fade-left"
        data-aos-duration="1000"
        data-aos-offset="300"
        data-aos-delay="100"
        item
        xs={4}
        sm={4}
        md={4}
        sx={{
          textAlign: "center",
          pt: "20px",
          color: "white",
          borderColor: "white",
        }}
      >
        <img
          src="assets/ah-golden-logo.png"
          alt="img"
          class="logo-devloper-ah"
        />
      </Grid>
    </Grid>
  );
};

export default DevloperComponets;
