import React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Menu,
  MenuItem,
  Collapse,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { ExpandMore, ExpandLess, Menu as MenuIcon } from "@mui/icons-material";

const HeaderComponents = ({ open, onClose }) => {
  const [openMenu, setOpenMenu] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuToggle = () => {
    setOpenMenu(!openMenu);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{ width: 300 }} // Adjust the width value as needed
    >
      <ul className="menu-header">
        <li>menu </li>
        <li>
          <IconButton
            className="close-btn"
            onClick={onClose}
            aria-label="Close drawer"
          >
            <img src="assets/cross-icon.svg" />
          </IconButton>
        </li>
      </ul>

      {/* <IconButton
        className="close-btn"
        onClick={onClose}
        aria-label="Close drawer"
      >
        x
      </IconButton> */}

      <ul className="menu-container">
        <a className="text-black-nav" href="/">
          <li className="nav-link-btn">
            Home
            <img
              src="assets/menu-arrow-icon-right.svg"
              className="menu-arrow"
            />
          </li>
        </a>

        <a className="text-black-nav" href="/experience">
          <li className="nav-link-btn ">
            EXPERIENCES
            <img
              src="assets/menu-arrow-icon-right.svg"
              className="menu-arrow"
            />
          </li>
        </a>
        <a className="text-black-nav" href="/partners">
          <li className="nav-link-btn">
            Partners
            <img
              src="assets/menu-arrow-icon-right.svg"
              className="menu-arrow"
            />
          </li>
        </a>
      </ul>

      <div className="contact-menu">
        <ul className="contact-menu">
          <a className="text-black-nav contact-btn" href="/contact">
            <li className="contact-list">Contact us</li>
          </a>
        </ul>
      </div>
    </Drawer>
  );
};

export default HeaderComponents;
