// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img.logo-devloper-ah {
  width: 58%;
  margin-top: 4rem;
  justify-content: flex-end;
  flex-wrap: wrap;
  display: inline-flex;
  align-content: center;
  margin-left: 7rem;
}

h2 {
  font-family: "Playfair Display" !important;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

p {
  font-family: "Helvetica Neue", sans-serif;

  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
`, "",{"version":3,"sources":["webpack://./src/components/DevloperComponets.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,gBAAgB;EAChB,yBAAyB;EACzB,eAAe;EACf,oBAAoB;EACpB,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,0CAA0C;EAC1C,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,yCAAyC;;EAEzC,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":["img.logo-devloper-ah {\n  width: 58%;\n  margin-top: 4rem;\n  justify-content: flex-end;\n  flex-wrap: wrap;\n  display: inline-flex;\n  align-content: center;\n  margin-left: 7rem;\n}\n\nh2 {\n  font-family: \"Playfair Display\" !important;\n  font-size: 32px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  text-transform: uppercase;\n}\n\np {\n  font-family: \"Helvetica Neue\", sans-serif;\n\n  font-style: normal;\n  font-weight: 300;\n  line-height: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
