// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub-title {
  color: var(--Black, #000);
  font-family: "Playfair Display";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Feature-container {
  padding-bottom: 50px;
}

.title {
  color: #313b2f !important;
  font-family: "Playfair Display", sans-serif !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  text-transform: uppercase !important;
}

.sub-title {
  color: #000 !important;
  font-family: "Playfair Display", sans-serif !important;
  font-size: 32px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.body-sub {
  color: var(--Black, #000);
  font-family: "Helvetica Neue", sans-serif !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.secound-bg-estate {
  border-radius: 16px;
  background: rgba(203, 197, 179, 0.4) !important;
}

.third-bg-estate {
  border-radius: 16px;
  background: rgba(203, 197, 179, 0.4) !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/EstateComponent.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,+BAA+B;EAC/B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;EACzB,sDAAsD;EACtD,0BAA0B;EAC1B,6BAA6B;EAC7B,2BAA2B;EAC3B,8BAA8B;EAC9B,oCAAoC;AACtC;;AAEA;EACE,sBAAsB;EACtB,sDAAsD;EACtD,0BAA0B;EAC1B,6BAA6B;EAC7B,2BAA2B;EAC3B,8BAA8B;AAChC;;AAEA;EACE,yBAAyB;EACzB,oDAAoD;EACpD,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,+CAA+C;AACjD;;AAEA;EACE,mBAAmB;EACnB,+CAA+C;AACjD","sourcesContent":[".sub-title {\n  color: var(--Black, #000);\n  font-family: \"Playfair Display\";\n  font-size: 32px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n}\n.Feature-container {\n  padding-bottom: 50px;\n}\n\n.title {\n  color: #313b2f !important;\n  font-family: \"Playfair Display\", sans-serif !important;\n  font-size: 20px !important;\n  font-style: normal !important;\n  font-weight: 500 !important;\n  line-height: normal !important;\n  text-transform: uppercase !important;\n}\n\n.sub-title {\n  color: #000 !important;\n  font-family: \"Playfair Display\", sans-serif !important;\n  font-size: 32px !important;\n  font-style: normal !important;\n  font-weight: 500 !important;\n  line-height: normal !important;\n}\n\n.body-sub {\n  color: var(--Black, #000);\n  font-family: \"Helvetica Neue\", sans-serif !important;\n  font-size: 24px;\n  font-style: normal;\n  font-weight: 300;\n  line-height: normal;\n}\n\n.secound-bg-estate {\n  border-radius: 16px;\n  background: rgba(203, 197, 179, 0.4) !important;\n}\n\n.third-bg-estate {\n  border-radius: 16px;\n  background: rgba(203, 197, 179, 0.4) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
