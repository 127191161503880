import React from "react";
import ContactComponent from "./ContactComponent";
import Footer from "./Footer";
import SecoundHeaderComponents from "./SecoundHeaderComponents";
const Contact = () => {
  return (
    <div className="section-padding">
      <SecoundHeaderComponents />
      <ContactComponent />
      <Footer />
    </div>
  );
};

export default Contact;
